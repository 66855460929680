import React from "react";
import N_C_M from "../Nature_cache/N_C_M";
import U_N from "../Unnatural_nature/U_N";
import G_G from "../Gardening/G_G";
import M_N from "../Mourning_nature/M_N";
import NavBar from "../NavBar/NavBar";

const Home = () => {
  return (
    <>
      <NavBar />
      <div className="main-container">
        <N_C_M />
        <br></br>
        <G_G />
        <br></br>
        <U_N />
        <br></br>
        <M_N />
      </div>
    </>
  );
};
export default Home;
