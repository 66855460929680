import React from "react";
import Home from "./components/Homepage/home";
import Projects from "./components/Projects/project";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// old website
class App extends React.Component {
  render() {
    return (
      <>
        <header></header>
        <div>
          <div className="App">
            <Router>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/research" element={<Projects/>}/>
              </Routes>
            </Router>
          </div>
        </div>
        {/* <footer>
          <p>© 2022, J.K.Richards. All Rights Reserved</p>
        </footer> */}
      </>
    );
  }
}

export default App;
