import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import "../Modal/Style.css";

class G_G extends React.Component {
    state = {
        isOpen: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    render() {
        return (
            <>
                <div className="description">
                    <div className="title">
                        <button
                            className="openModalBtn"
                            onClick={this.toggleModal}>
                            Gardening an archive
                        </button>
                        <CSSTransition
                            in={this.state.isOpen}
                            timeout={300}
                            classNames="dialog"
                        >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                ariaHideApp={false}
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 10,
                                        right: 10,
                                        bottom: 0,
                                        border: '1px rgba(0, 0, 0, 0.1',
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        overflow: 'hidden',
                                    }
                                }}
                            >

                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="titleCloseBtn">
                                            <button onClick={this.toggleModal}>X</button>
                                        </div>
                                        <div className="body">
                                            <h2>
                                                Gardening an archive
                                            </h2>
                                            <br></br>
                                            <br></br>
                                            <div className='reactplayer'>
                                                <iframe title="Garden" className="responsive-iframe" src="https://player.vimeo.com/video/736527139?h=cb2c4a66c5&speed=0&quality=undefined&app_id=122963&texttrack=undefined"></iframe>
                                            </div>
                                            <br></br>
                                            <h4>
                                                Recording of performance, 5:00
                                            </h4>
                                            <br></br>
                                            <p>
                                                <p>
                                                    The current state of the world has issued care with a greater importance within society, how, why and who do we care for. Yet, the act of care preserves a subject through control. This work explores these notions, set within the boundaries of the garden. To critically engage with care and control this moving-image piece will be composed by gardening with film. Ralph Waldo Emerson described the superiority of the “essences unchanged by man; space, the air, the river, the leaf”,<h5>1</h5> an appreciation of purity. However, arguably the pure image of nature is a myth.
                                                    <br></br>
                                                    <br></br>
                                                    Whilst nature may seem innately familiar to us given our constant interaction with it, the notion is complex and contradictory. The event is mediated through its domestication, evident through its cultivation, competitions and cuttings, acts that could be seen as violent. Thus is our relationship to nature unnatural? To unearth this violence, the medium of film will be used to compile acts of care and control. Wrapping, transplanting, clipping. These motions will be composed to create performance, captured through the lens of the camera. Like a garden, an image is maintained by the boundaries of the frame, preserving the image into an archive. Consequently forming an archive of care.
                                                    <br></br>
                                                    <br></br>
                                                    Gardening with film, through methods of structuring, organising and cutting, will critically examine the archive. Emphasising the stilling of living objects, in relation to the colonial capture of nature, which is inherent within the English landscape. “what is the relationship between gardening and conquest? Is the conqueror a gardener and the conquered who works in the field?”.<h5>2</h5> Unnatural Nature intends to expose our coarse grasp of nature, which has been masked by the touch of care.
                                                </p>
                                                <br></br>
                                                <br></br>
                                                <h4>
                                                    1, Emerson, Ralph Waldo. 1836, Nature. (Boston: James Munroe and Company)
                                                    <br></br>
                                                    2, Kincaid, Jamaica. 2001. My Garden (Book) (Farrar, Straus and Giroux; Illustrated Edition)
                                                </h4>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                            </p>
                                        </div>
                                        <div className="footer">
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </CSSTransition>
                    </div>
                </div>
            </>
        );
    }
}

export default G_G;