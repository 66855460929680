import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import "../Projects/project.css";
import Image1 from "../../media/JKR_flower_gameplay.gif"
import Image2 from "../../media/JKR_tracking.gif"
import Image3 from "../../media/JKR_collapse.gif"
import Image5 from "../../media/JKR_storm.gif"



class Projects extends React.Component {
    state = {
        isOpen: true,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    render() {
        return (
            <>
                <div className="description">
                    <div>
                        {/* <button
                            className="openModalBtn"
                            onClick={this.toggleModal}>
                            Nature Cache
                        </button> */}
                        <CSSTransition
                            in={this.state.isOpen}
                            timeout={300}
                            classNames="dialog"
                        >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                ariaHideApp={false}
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 10,
                                        right: 10,
                                        bottom: 0,
                                        border: '1px rgba(0, 0, 0, 0.1',
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        overflow: 'auto',
                                    }
                                }}
                            >
                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="titleCloseBtn">
                                            <button onClick={this.toggleModal}>X</button>
                                        </div>
                                        <div className="body">
                                            <h2>
                                                Research
                                            </h2>
                                            <br></br>
                                            <br></br>
                                            <div className='reactplayer'>
                                                <iframe title="Nature" className="responsive-iframe" src="https://player.vimeo.com/video/736528691?h=e51e26039b&speed=0&quality=undefined&app_id=122963&texttrack=undefined"></iframe>
                                            </div>
                                            <br></br>
                                            <h4>
                                                Growing digital species
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <img src={Image1} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Mapping virtual landscapes
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <div className='reactplayer'>
                                                <iframe title="Nature" className="responsive-iframe" src="https://player.vimeo.com/video/736528691?h=e51e26039b&speed=0&quality=undefined&app_id=122963&texttrack=undefined"></iframe>
                                            </div>
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Mapping online wind
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <img src={Image2} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Tracking evolution
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <img src={Image3} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Tree collapsing in a digital vacuum
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <img src={Image2} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Evolution of GAN leaf species
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <img src={Image5} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Simulating wind in a digital vacuum
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <img src={Image5} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Simulating wind in a physical vacuum
                                            </h4>





                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </CSSTransition>
                    </div>
                </div>
            </>
        );
    }
}

export default Projects;



