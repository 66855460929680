import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import "../Modal/Style.css";
//import Image1 from "../../media/Test_1.png"
import Image2 from "../../media/JKR_IN_p19.jpg"
import Image3 from "../../media/JKR_IN_p44.jpg"



class M_N extends React.Component {
    state = {
        isOpen: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    render() {
        return (
            <>
                <div className="description">
                    <div className="title">
                        <button
                            className="openModalBtn"
                            onClick={this.toggleModal}>
                            In Memoriam
                        </button>
                        <CSSTransition
                            in={this.state.isOpen}
                            timeout={300}
                            classNames="dialog"
                        >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                ariaHideApp={false}
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 10,
                                        right: 10,
                                        bottom: 0,
                                        border: '1px rgba(0, 0, 0, 0.1',
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        overflow: 'hidden',
                                    }
                                }}
                            >
                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="titleCloseBtn">
                                            <button onClick={this.toggleModal}>X</button>
                                        </div>
                                        <div className="body">
                                            <h2>
                                                In Memoriam
                                            </h2>
                                            <br></br>
                                            <p>
                                                ⋱
                                                You may be fearful of death. To lose something, or someone, is a deeply challenging process. Melancholy commences as one seeks to hold onto memories. This sombre may never depart. Yet to progress beyond the holding onto an objectified loss, mourning must occur to establish connections and communion with others experiencing loss. Humans are intrinsically related to Nature, which should make watching its loss at the impact of climate change conflicting, considering we are to blame.
                                                ⋰
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <img src={Image2} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <img src={Image3} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Scans of document, In Memoriam.
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </CSSTransition>
                    </div>
                </div>
            </>
        );
    }
}

export default M_N;