import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import "../Modal/Style.css";
import Image1 from "../../media/JKR_scan.jpg"


class U_N extends React.Component {
    state = {
        isOpen: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    render() {
        return (
            <>
                <div className="description">
                    <div className="title">
                        <button
                            className="openModalBtn"
                            onClick={this.toggleModal}>
                            Unnatural Nature
                        </button>
                        <CSSTransition
                            in={this.state.isOpen}
                            timeout={300}
                            classNames="dialog"
                        >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                ariaHideApp={false}
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 10,
                                        right: 10,
                                        bottom: 0,
                                        border: '1px rgba(0, 0, 0, 0.1',
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        overflow: 'hidden',
                                    }
                                }}
                            >
                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="titleCloseBtn">
                                            <button onClick={this.toggleModal}>X</button>
                                        </div>
                                        <div className="body">
                                            <h2>
                                                Unnatural Nature

                                            </h2>
                                            <h3>
                                                Text hosted by. . .
                                                <a href="https://ho.ldin.gs/#richards" className="nav__link">
                                                    High Holdings
                                                </a>
                                            </h3>
                                            <br></br>
                                            <p>
                                                ⋱
                                                Pixels penetrating my irises vanish, a residuum of darkness remains. The synthetic mouse living on my work surface has not moved in five minutes, authorising the computer screen to turn off. Darkness is often an environment of rest, yet I have been awoken from the enchantment of the screen.
                                                ⋰
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <img src={Image1} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Scan of Collins Nature Guide, Wild Flowers of Britain and Europe, Index.
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </div>
                                        <div className="footer">
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </CSSTransition>
                    </div>
                </div>
            </>
        );
    }
}

export default U_N;