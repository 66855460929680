import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import "../Modal/Style.css";
import Image1 from "../../media/JKR_NM_p22.jpg"



class N_C_M extends React.Component {
    state = {
        isOpen: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    render() {
        return (
            <>
                <div className="description">
                    <div>
                        <button
                            className="openModalBtn"
                            onClick={this.toggleModal}>
                            Nature Cache
                        </button>
                        <CSSTransition
                            in={this.state.isOpen}
                            timeout={300}
                            classNames="dialog"
                        >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                ariaHideApp={false}
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 10,
                                        right: 10,
                                        bottom: 0,
                                        border: '1px rgba(0, 0, 0, 0.1',
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        overflow: 'auto',
                                    }
                                }}
                            >
                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="titleCloseBtn">
                                            <button onClick={this.toggleModal}>X</button>
                                        </div>
                                        <div className="body">
                                            <h2>
                                                Nature Cache
                                            </h2>
                                            <br></br>
                                            <br></br>
                                            <div className='reactplayer'>
                                                <iframe title="Nature" className="responsive-iframe" src="https://player.vimeo.com/video/736528691?h=e51e26039b&speed=0&quality=undefined&app_id=122963&texttrack=undefined"></iframe>
                                            </div>
                                            <br></br>
                                            <h4>
                                                Film, 3:31
                                            </h4>
                                            <br></br>
                                            <p>
                                                Wandering through digital space, reality is weightless. Measurements are by the thousands. Mirroring a mental experience, a para-physical experience that is conjured by a mind whose faculties are failing. May stood for 4 hours to water a potted plant. They suffer from dementia. Individuals' experience of the condition can be very personal. May’s encounter caused seemingly unconventional acts to occur, permeating the boundaries between home and nature. A new understanding of nature must be imagined, as current forms of ecology are taken away by human control.
                                                <br></br>
                                                <br></br>
                                                Nature Cache wanders a new way to live with nature, a permeable relationship constructed through the memory of May. The para-physical quality of the memories in question entail the application of virtual space, as realities parameters do not suffice. The weightlessness of the mind in a state of decline, an imagination that is not bound by conventional regulations of human thought is likened to the abundance of parameters in digital space, floating is possible when gravity is transformed to -1.
                                                <br></br>
                                                <br></br>
                                                Nature is uncanny online. Nature Cache utilises the metrics of digital space to reconstruct and extend memories during May’s experience with dementia. Simulating natural events within a virtual vacuum, questioning new permeable ways of living with nature. Warping the home beyond its physical state, extending it beyond its perimeter, leaving a digital stain. Like Derek Jarman’s garden, boundaries are permeable and endless, merging home and land together.
                                            </p>
                                            <br></br>
                                            <br></br>
                                            <img src={Image1} alt="img" className="responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>
                                                Scans of document, Nature Cache.
                                            </h4>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </CSSTransition>
                    </div>
                </div>
            </>
        );
    }
}

export default N_C_M;