import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
import "../Modal/Style.css";

class NavBar extends React.Component {
    state = {
        isOpen: false,
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    };
    render() {
        return (
            <>
                <nav className="nav">
                    <div className="nav__brand">
                        <button
                            className="openModalBtn"
                            onClick={this.toggleModal}>
                            <h1>
                                <span className="inverse">
                                    Joshua Richards&nbsp;
                                </span>
                            </h1>
                        </button>
                        <CSSTransition
                            in={this.state.isOpen}
                            timeout={300}
                            classNames="dialog"
                        >
                            <Modal
                                closeTimeoutMS={500}
                                isOpen={this.state.isOpen}
                                ariaHideApp={false}
                                style={{
                                    overlay: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        position: 'fixed',
                                        top: 0,
                                        left: 10,
                                        right: 10,
                                        bottom: 0,
                                        border: '1px rgba(0, 0, 0, 0.1',
                                        background: 'rgba(0, 0, 0, 0.1)',
                                        overflow: 'hidden',
                                    }
                                }}
                            >
                                <div className="modalBackground">
                                    <div className="modalContainer">
                                        <div className="titleCloseBtn">
                                            <button onClick={this.toggleModal}>X</button>
                                        </div>
                                        <div className="body">
                                            <h2>
                                                Bio
                                            </h2>
                                            <br></br>
                                            <p>
                                                Born in a town on the periphery of London (1997, Anthropocene). Prior to this, ice covered the land throughout the Anglican Glaciation. 1000m deep ice drifted around, diverting the River Thames to its current flow (1.8-0.01 mya, Pleistocene). Once the whole of Great Britain drifted, as it departed from Pangaea, encountering a series of floods by the Atlantic. A mass extinction preceded the flooding, deeply affecting life across the biosphere (240-145 mya, Triassic-Jurassic).


                                            </p>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <h2>
                                                Contact
                                            </h2>
                                            <a href="mailto:joshuakeithrichards@gmail.com" className="nav__link">
                                                <br></br>
                                                joshuakeithrichards@gmail.com
                                            </a>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </CSSTransition>
                    </div>
                </nav>
            </>
        );
    }
}
export default NavBar;
